import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Location } from '@reach/router';

import { Topbar, Sidebar, Footer } from './components';

import pages from '../navigation';
import { loadFonts /* , useIntersectionObserver */ } from 'utils';
import { customFontList } from 'theme';
import { SiteMaxWithProvider } from 'theme/siteMaxWithContext';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]');
}

interface Props {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
  sectionTitle?: string;
}

const Main = ({
  children,
  colorInvert = false,
  bgcolor,
  sectionTitle,
}: // bgcolor = 'transparent',
Props): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // Start page from top on load
  useEffect(() => {
    try {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
    } catch (error) {
      // do nothing
    }
  }, []);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  // const [withinHero, setWithinHero] = useState(true);

  useEffect(() => {
    loadFonts(Object.keys(customFontList), () => setFontsLoaded(true));
  }, []);

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (): void => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const anchorRef = React.useRef(null);

  // const handleWithinHero = () => setWithinHero(false);
  // const handleOutsideHero = () => setWithinHero(true);

  // useIntersectionObserver(
  //   anchorRef.current,
  //   handleWithinHero,
  //   handleOutsideHero,
  // );

  return (
    <Location>
      {(locationProps) =>
        fontsLoaded && (
          <SiteMaxWithProvider>
            <Box>
              <Topbar
                onSidebarOpen={handleSidebarOpen}
                pages={pages}
                colorInvert={trigger ? false : colorInvert}
                sectionTitle={sectionTitle}
                withinHero={!trigger}
                {...locationProps}
              />
              <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
              />
              <main style={{ backgroundColor: bgcolor }}>
                {children}
                <Divider />
              </main>
              <Footer />
              <div
                ref={anchorRef}
                style={{
                  position: 'absolute',
                  width: '1px',
                  height: '1px',
                  top: '756px', // height of Hero section
                  left: 0,
                }}
              />
            </Box>
          </SiteMaxWithProvider>
        )
      }
    </Location>
  );
};

export default Main;
