import * as React from 'react';
import Cookies from 'js-cookie';

const useCookieFromClickID = (): { clickID?: string } => {
  const [clickID, setClickID] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clickIDFromParams = urlParams.get('external_id');

    if (clickIDFromParams) {
      Cookies.set('external_id', clickIDFromParams);
    }
  }, []);

  const get = (): string | undefined => {
    return Cookies.get('external_id');
  };

  React.useEffect(() => {
    const cookieValue = get();
    setClickID(cookieValue);
  }, []);

  return { clickID };
};

export default useCookieFromClickID;
