import React from 'react';
import Box from '@mui/material/Box';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

interface Props {
  children: React.ReactNode;
  // All other props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Container = ({ children, ...rest }: Props): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Box
      maxWidth={{ sm: 720, md: `${sizeOfGrid}px` }}
      width={1}
      margin={'0 auto'}
      paddingX={2}
      paddingY={{ xs: 4, sm: 6, md: 8 }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Container;
