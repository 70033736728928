import React, { useEffect, useState } from 'react';
import { /* propertiesActiveWidth, */ siteWidth } from 'theme';
import { /* getPrimaryLink, */ gridSize } from 'utils';

type ISiteMaxWithContext = [
  number,
  React.Dispatch<React.SetStateAction<number>>,
];

const SiteMaxWithContext = React.createContext<ISiteMaxWithContext>([
  gridSize(siteWidth),
  () => null,
]);

const SiteMaxWithProvider = ({ children }: { children: any }) => {
  const [sizeOfGrid, setSizeOfGrid] = useState(gridSize(siteWidth));
  const pathname = location.pathname.slice(0, -1) || '/';
  const hash = location.hash || '';
  const path = hash ? pathname + hash : pathname;

  // const isPropertiesSection = getPrimaryLink(path).includes('properties');

  useEffect(() => {
    // if (isPropertiesSection) setSizeOfGrid(gridSize(propertiesActiveWidth));
    // if (!isPropertiesSection)
    setSizeOfGrid(gridSize(siteWidth));
  }, [path]);

  return (
    <SiteMaxWithContext.Provider value={[sizeOfGrid, setSizeOfGrid]}>
      {children}
    </SiteMaxWithContext.Provider>
  );
};

export { SiteMaxWithContext, SiteMaxWithProvider };
