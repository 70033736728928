import { useLocalization } from 'gatsby-theme-i18n';
import { $SpecialObject } from 'i18next/typescript/helpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  KeyValues,
  KeyValueList,
} from 'views/Properties/components/KeyValueList/KeyValueList';

export const useLocalise = (viewName: string, sectionName: string) => {
  const { t } = useTranslation();

  // Prefix the translation key with the component name and a separator
  function localise(key: string) {
    // Helper function to convert the first character to lowercase
    const lowerFirstChar = (str: string) =>
      str.charAt(0).toLowerCase() + str.slice(1);

    // Ensure the first letter of viewName and sectionName are lowercase
    const localizedViewName = lowerFirstChar(viewName);
    const localizedSectionName = lowerFirstChar(sectionName);

    const translationKey = `${localizedViewName}.${localizedSectionName}.${key}`;
    // console.info('Using translationKey:', translationKey);

    // Construct the translation key with the modified viewName and sectionName
    return t(translationKey, { returnObjects: true });
  }

  // Return the localise function so it can be used in the component
  return { localise };
};

interface RemappedObj {
  [key: string]: string | object | number;
}

/**
 * The `remapper` function is designed to map translation keys to their corresponding values within a given content object.
 * It accepts two arguments:
 * - The first argument specifies the content object that holds the actual content to be translated.
 * - The second argument identifies the specific keys within the content object that contain the translation keys.
 * For instance, if 'title' and 'content' are provided as keys, the remapper will target the translationKeys associated with these keys for translation.
 */

export const useRemapper =
  (localise: (key: string) => $SpecialObject) =>
  <T extends RemappedObj>(textContent: T, localisationSuffixes: string[]) => {
    const remapped: Partial<T> = { ...textContent };

    function remap(keyName: keyof T, supKey: string | object | number) {
      let valueToUse = supKey;
      if (Array.isArray(supKey)) {
        // If supKey is an array, use the first element.
        [valueToUse] = supKey;
      }
      // Pulling out translated content from translation.json based on the translationKey provided
      const localisedValue = localise(
        `${String(valueToUse)}.${String(keyName)}`,
      );
      remapped[keyName] = localisedValue as any;
    }

    // Iterate over the localisationSuffixes to apply remapping
    localisationSuffixes.forEach((suffix) => {
      if (suffix in textContent) {
        remap(suffix, textContent[suffix]);
      }
    });

    return remapped as T;
  };

type Tooltip = { tooltip: string[] };
type RowItem = (string | Tooltip)[];
type RowsInput = string;
type RowsOutput = RowItem[];

export function parseStringToArray(inputRows: RowsInput): RowsOutput {
  // Replace Ruby-like hash rockets with JSON-like colons before parsing.
  let outputRows: string = inputRows.replace(/=>/g, ':');

  // Remove newline characters from the string.
  outputRows = outputRows.replace(/\r?\n/g, '');

  // Parse the modified string into an array or object.
  return JSON.parse(outputRows);
}

export function useStringToArrayRows(
  localise: (key: string) => $SpecialObject,
  textContent: RemappedObj,
  arrayRowName: { rowName: string },
) {
  const { rowName } = arrayRowName;
  const remapper = useRemapper(localise);
  const { [rowName]: rowVar, ...restContent } = remapper(textContent, [
    'title',
    rowName,
  ]);

  return {
    ...restContent,
    [rowName]:
      typeof rowVar === 'string'
        ? (parseStringToArray(rowVar) as string[][])
        : rowVar,
  };
}

const useMappedKeyValueRows = (
  title: string,
  rows: Array<KeyValues | string[]>,
  config: KeyValues,
) => {
  const mappedRows: KeyValues[] = React.useMemo(() => {
    const convertedRows =
      typeof rows === 'string' ? parseStringToArray(rows) : rows;

    return convertedRows.map((row, index) => {
      const foundConfig = Array.isArray(config)
        ? config.find((c) => c?.translationIndex === index)
        : undefined;
      if (foundConfig) {
        const [rowKey, keyValue] = row as string[];

        // Apply translation override if it exists
        const modifiedRow = [
          foundConfig?.translationOverride?.[0] || rowKey,
          foundConfig?.translationOverride?.[1] || keyValue,
        ];

        // Exclude keys not needed in the final result
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { translationIndex, translationOverride, ...restConfig } =
          foundConfig;

        return { ...modifiedRow, ...restConfig };
      }
      return row;
    });
  }, [rows, config]);

  return { title, rows: mappedRows };
};

export const useLocaliseKeyValueList = (
  KeyValueListContent: KeyValueList,
  remapper: ReturnType<typeof useRemapper>,
) => {
  // Establishing remapper to map over content object by 1.pointing to content object, 2.selecting translationKey locations
  const localisedBaseContent = remapper(KeyValueListContent, ['title', 'rows']);
  const { title, rows } = localisedBaseContent;
  const [first, ...config] = KeyValueListContent.rows;
  // Remapping for KeyValueList content with extra config
  const localisedKeyValueListContent = useMappedKeyValueRows(
    title,
    rows as KeyValues[],
    config as unknown as KeyValues,
  );
  return localisedKeyValueListContent;
};

export const useLocalisedPath = () => {
  const { locale, defaultLang } = useLocalization();

  const localisedPath = (path: string): string => {
    if (locale === defaultLang) {
      return path;
    }
    return path.replace(/\/[a-zA-Z]{2}/, '');
  };

  return { localisedPath };
};
