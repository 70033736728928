import * as React from 'react';
import * as Mui from '@mui/material';
import * as SVG from './SVGs';
import { navigate } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import { useLocalisedPath } from 'hooks/use-localise';

const FLAGS = {
  da: <SVG.DanishFlag />,
  en: <SVG.USFlag />,
  es: <SVG.SpanishFlag />,
  de: <SVG.GermanFlag />,
};
type Flags = keyof typeof FLAGS;

export interface LanguageConfig {
  code: string;
  hrefLang: string;
  name: string;
  localName: string;
  langDir: 'ltr' | 'rtl';
}

function LanguageSwitcher({
  location,
  isHomeView,
}: {
  location: any;
  isHomeView: boolean;
}) {
  const { locale, defaultLang, config } = useLocalization();
  const { localisedPath } = useLocalisedPath();

  const rawPathname = location.pathname;
  const hash = location.hash || '';
  const path = hash ? rawPathname + hash : rawPathname;

  const switchLanguage = (langCode: LanguageConfig['code']) => {
    if (langCode === defaultLang) {
      navigate(`${localisedPath(path)}`);
    } else {
      navigate(`/${langCode}${localisedPath(path)}`);
    }
  };

  const handleChange = (event: Mui.SelectChangeEvent) => {
    const langCode = event.target.value;
    switchLanguage(langCode);
  };

  return (
    <Mui.FormControl
      sx={{
        m: 1,
        minWidth: 70,
        '&.MuiModal-root': {
          zIndex: 100000000000000,
        },
      }}
      size="small"
    >
      <Mui.InputLabel
        id="lang-select-label"
        sx={{
          width: '24px',
          color: isHomeView ? 'text.secondary' : 'black',
          '&.MuiInputLabel-root.Mui-focused': {
            color: isHomeView ? 'text.secondary' : 'black',
          },
        }}
      >
        {FLAGS[locale as Flags]}
      </Mui.InputLabel>
      <Mui.Select
        labelId="lang-select-label"
        id="lang-select"
        onChange={handleChange}
        label="Lang"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSvgIcon-root': {
            color: isHomeView ? 'text.secondary' : 'black',
          },
        }}
      >
        <Mui.MenuItem value="" disabled sx={{ display: 'flex', gap: '5px' }}>
          {FLAGS[locale as Flags]} <em>{locale.toUpperCase()}</em>
        </Mui.MenuItem>
        {(config as LanguageConfig[]).map(
          (c) =>
            c.code !== locale && (
              <Mui.MenuItem
                key={c.code}
                value={c.code}
                sx={{ display: 'flex', gap: '5px' }}
              >
                {FLAGS[c.code as Flags]}
                {c.code.toUpperCase()}
              </Mui.MenuItem>
            ),
        )}
      </Mui.Select>
    </Mui.FormControl>
  );
}

export default LanguageSwitcher;
