import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ThemeContainer from 'components/Container';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import siteLogo from 'images/logo-traced.svg';
import { Divider /* , getPrimaryLink */ } from 'utils';
import { Investor_Platform_URL } from 'links';
import * as Styles from './Topbar.styles';
import Link from '../Footer/FooterLink';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import HamburgerMenu from './HamburgerMenu';
import { useLocalise, useLocalisedPath } from 'hooks/use-localise';
import LanguageSwitcher from './components/languageSwitcher';
import useCookieFromClickID from 'hooks/useCookieFromClickID';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages?: any;
  sectionTitle?: string;
  onSidebarOpen?: () => void;
  colorInvert?: boolean;
  withinHero: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
}

export type LinkData = {
  key: number;
  name: string;
  href: string;
};

const mappedMenu = (data: LinkData[]) => {
  if (!data)
    return {
      menu: [],
    };
  const regularItems = [...data];
  const loginItem = regularItems.pop();
  const homeItem = regularItems.shift();

  return {
    menu: regularItems,
    loginItem,
    homeItem,
  };
};

const Topbar = ({
  /* pages, */ sectionTitle,
  withinHero,
  location,
}: Props): JSX.Element => {
  const { clickID } = useCookieFromClickID();
  const rawPathname = location.pathname || '/';
  const pathname = rawPathname.slice(0, -1);
  const hash = location.hash || '';
  const path = hash ? pathname + hash : pathname;
  const { localisedPath } = useLocalisedPath();
  const isHomeView =
    (localisedPath(rawPathname) === '/' ||
      localisedPath(rawPathname) === '/home') &&
    withinHero;

  const [open, setOpen] = React.useState(false);
  const menuExpanded = open;
  const { localise } = useLocalise('Topbar', 'link');

  const hardCodedLinks = [
    {
      _key: 1,
      title: 'start',
      link: '/',
    },
    {
      _key: 2,
      title: 'projectInsights',
      link: '/properties/grev55',
    },
    {
      _key: 3,
      title: 'logIn',
      link:
        Investor_Platform_URL +
        '/sign-in' +
        (clickID ? `?external_id=${clickID}` : ''),
    },
  ];

  const navigationLinks = hardCodedLinks.map((item) => ({
    key: item._key,
    name: localise(item.title) as unknown as string,
    href: item.link,
  }));
  const { loginItem, ...renderedLinks } = mappedMenu(navigationLinks);

  // const isPrimaryPageActive = (item: LinkData) =>
  //   getPrimaryLink(localisedPath(path)).includes(getPrimaryLink(item.href));

  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          height: {
            sm: '24px',
            md: '48px',
          },
          background: isHomeView ? Styles.transparent : 'white',
          marginBottom: {
            lg: '0',
          },
          display: { xs: 'none', sm: 'block' },
        }}
      />
      <Styles.MenuContainer
        position="sticky"
        ishomeview={isHomeView.toString() as 'true' | 'false'}
        menuexpanded={menuExpanded.toString() as 'true' | 'false'}
      >
        <ThemeContainer
          maxWidth={`${sizeOfGrid}px`}
          paddingY={1}
          sx={{ paddingRight: { xs: 0, md: 2 } }}
        >
          <Toolbar
            disableGutters
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box
              flex="1"
              paddingRight={{
                xs: '0',
                sm: '30px',
                md: '30px',
              }}
            >
              <Link href="/">
                <Box height={{ xs: '30px', sm: '40px', md: '54px' }}>
                  <Styles.Logo
                    src={siteLogo}
                    component={'img'}
                    alt="Coreestate tokenized consumer rentals logo"
                    height={1}
                    ishomeview={isHomeView}
                  />
                </Box>
              </Link>
            </Box>

            <Box
              flex="1"
              textAlign="right"
              whiteSpace="nowrap"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Hidden mdDown implementation="css">
                <Box
                  flex="2"
                  sx={{
                    whiteSpace: 'nowrap',
                    paddingRight: { lg: '1rem', md: '1rem' },
                  }}
                >
                  {renderedLinks?.menu.map((item, index) => (
                    <Styles.LinkStyled
                      ishomeview={isHomeView.toString() as 'true' | 'false'}
                      key={item.key}
                      sx={{
                        color: isHomeView ? 'text.secondary' : 'black',
                      }}
                      href={item.href === '/' ? item.href : item.href + '/'}
                    >
                      {item.name}
                    </Styles.LinkStyled>
                  ))}
                  <Styles.LinkStyled
                    ishomeview={isHomeView.toString() as 'true' | 'false'}
                    sx={{
                      textDecoration:
                        path === loginItem?.href ? 'underline' : 'none',
                      color: isHomeView ? 'text.secondary' : 'black',
                    }}
                    href={loginItem?.href || ''}
                  >
                    {!!loginItem?.name && loginItem?.name}
                  </Styles.LinkStyled>
                </Box>
              </Hidden>
              <LanguageSwitcher location={location} isHomeView={isHomeView} />
              <Box
                sx={{ display: { xs: 'inline-block', md: 'none' } }}
                alignItems={'center'}
              >
                <MenuButton
                  isHomeView={isHomeView}
                  handleClick={() => setOpen(!open)}
                />
              </Box>
            </Box>
          </Toolbar>

          <Hidden mdUp implementation="css">
            <HamburgerMenu
              open={open}
              path={path}
              isHomeView={isHomeView}
              loginItem={loginItem}
              renderedLinks={renderedLinks}
            />
          </Hidden>
        </ThemeContainer>
      </Styles.MenuContainer>
      {sectionTitle && (
        <Styles.SectionTitle sx={{ maxWidth: `${sizeOfGrid - 25}px` }}>
          <Divider height="3.3em" />
          <Styles.MainTitle className="sectionTitle-title" component="h1">
            {sectionTitle}
          </Styles.MainTitle>
        </Styles.SectionTitle>
      )}
    </>
  );
};

export default Topbar;

const MenuButton = ({
  isHomeView,
  handleClick,
}: {
  isHomeView: boolean;
  handleClick: () => void;
}) => (
  <Button
    onClick={handleClick}
    aria-label="Menu"
    variant={'text'}
    sx={{
      minWidth: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginRight: { xs: '0' },
      marginLeft: { xs: '0' },
    }}
  >
    <MenuIcon
      sx={{
        color: isHomeView ? 'text.secondary' : 'black',
      }}
    />
  </Button>
);
