import * as React from 'react';
import { ResponsiveStyleValue, styled, Theme } from '@mui/system';
import { Color, Link as MaterialLink } from '@mui/material';
import { LocalizedLink } from 'gatsby-theme-i18n';
import './classes.css';

type LinkComponent = {
  children: React.ReactNode;
  href: string;
  target?: string;
  color?: string;
  language?: string;
  style?: { [key: string]: string | number };
};

const linkStyle = (
  color: string | ResponsiveStyleValue<Color | Color[] | undefined>,
  theme: Theme,
) => `
  text-transform: none;
  font-family: Roboto Regular;
  cursor: pointer;
  text-decoration: unset;
  color: ${color ? color : theme.palette.primary.main};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledGatsbyLink = styled(LocalizedLink)`
  ${({ theme, color }) => linkStyle(color, theme)}
`;

const StyledMaterialLink = styled(MaterialLink)`
  ${({ theme, color }) =>
    linkStyle(
      color as ResponsiveStyleValue<Color | Color[] | undefined>,
      theme,
    )}
`;

const Link = ({
  children,
  href,
  color,
  language,
  ...other
}: LinkComponent): JSX.Element => {
  const internal = /^\/(?!\/)/.test(href);

  const hashIndex = href?.indexOf('#');
  const hasHash = hashIndex !== -1;
  const hashStrippedHref = hasHash ? href?.substring(0, hashIndex) : '';
  const isCurrentCategory =
    hasHash && window.location.pathname.includes(hashStrippedHref);

  // Use gatsby link for internal non-anchor links and anchor links from different category
  if (internal && (!hasHash || !isCurrentCategory)) {
    return (
      <StyledGatsbyLink
        to={href}
        activeClassName="link-active"
        className="link"
        color={color}
        language={language}
        partiallyActive={true}
        {...other}
      >
        {children}
      </StyledGatsbyLink>
    );
  }

  return (
    <StyledMaterialLink href={href} className="link" color={color} {...other}>
      {children}
    </StyledMaterialLink>
  );
};

export default Link;
