import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionActions } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import * as Styles from './Topbar.styles';
import { LinkData } from './Topbar';

type HamburgerProps = {
  open: boolean;
  path: string;
  isHomeView: boolean;
  loginItem?: LinkData;
  renderedLinks: {
    menu: LinkData[];
    actionItem?: LinkData;
    loginItem?: LinkData;
  };
};

const HamburgerMenu = ({
  open,
  path,
  isHomeView,
  loginItem,
  renderedLinks,
}: HamburgerProps) => {
  return (
    <Accordion
      disableGutters
      expanded={open}
      disabled
      sx={{
        backgroundColor: 'unset',
        boxShadow: 'unset',
        border: 'unset',
        position: 'unset',
        marginLeft: '-16px',
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionActions
        sx={{
          padding: 0,
        }}
      />
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          textAlign="center"
        >
          {[loginItem, ...renderedLinks.menu].map((item) => (
            <Styles.LinkStyled
              key={item?.key}
              ishomeview={isHomeView.toString() as 'true' | 'false'}
              sx={{
                textDecoration: path === item?.href ? 'underline' : 'none',
                color: isHomeView ? 'text.secondary' : 'black',
              }}
              href={item?.href || ''}
            >
              {item?.name}
            </Styles.LinkStyled>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default HamburgerMenu;

export const MenuButton = ({
  isHomeView,
  handleClick,
}: {
  isHomeView: boolean;
  handleClick: () => void;
}) => (
  <Button
    onClick={handleClick}
    aria-label="Menu"
    variant={'text'}
    sx={{
      minWidth: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginRight: { xs: '0' },
      marginLeft: { xs: '0' },
    }}
  >
    <MenuIcon
      sx={{
        color: isHomeView ? 'text.secondary' : 'black',
      }}
    />
  </Button>
);
